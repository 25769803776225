// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-devhub-js": () => import("./../../../src/pages/aboutDevhub.js" /* webpackChunkName: "component---src-pages-about-devhub-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-journeys-js": () => import("./../../../src/pages/journeys.js" /* webpackChunkName: "component---src-pages-journeys-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-topic-js": () => import("./../../../src/pages/topic.js" /* webpackChunkName: "component---src-pages-topic-js" */),
  "component---src-pages-topics-js": () => import("./../../../src/pages/topics.js" /* webpackChunkName: "component---src-pages-topics-js" */),
  "component---src-templates-events-js": () => import("./../../../src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-journey-entry-default-js": () => import("./../../../src/templates/JourneyEntry_default.js" /* webpackChunkName: "component---src-templates-journey-entry-default-js" */),
  "component---src-templates-journey-github-default-js": () => import("./../../../src/templates/JourneyGithub_default.js" /* webpackChunkName: "component---src-templates-journey-github-default-js" */),
  "component---src-templates-past-events-js": () => import("./../../../src/templates/pastEvents.js" /* webpackChunkName: "component---src-templates-past-events-js" */),
  "component---src-templates-resource-type-js": () => import("./../../../src/templates/resourceType.js" /* webpackChunkName: "component---src-templates-resource-type-js" */),
  "component---src-templates-source-github-default-js": () => import("./../../../src/templates/SourceGithub_default.js" /* webpackChunkName: "component---src-templates-source-github-default-js" */),
  "component---src-templates-stand-alone-git-hub-raw-js": () => import("./../../../src/templates/StandAlone_GitHubRaw.js" /* webpackChunkName: "component---src-templates-stand-alone-git-hub-raw-js" */)
}

